import React from "react"
import { graphql } from "gatsby"
import { InnerPageNavigation } from "../components/Navigation"
import { Layout } from "../components/Layout"
import { PageTileGroup } from "../components/PageTile"
import { MDXRenderer } from "gatsby-plugin-mdx"
import {
  GatsbyImage as Img,
  getImage,
  ImageDataLike,
} from "gatsby-plugin-image"
import { SidebarItem } from "./types"

interface InnerpageWithSidebarTemplateProps {
  title: string
  description: string
  namespace: string
  sidebar: {
    title: string
    items: SidebarItem[]
  }
  sideart: ImageDataLike
  body: string
}

export const InnerpageWithSidebarTemplate: React.FC<
  InnerpageWithSidebarTemplateProps
> = ({ title, description, body, namespace, sidebar, sideart }) => {
  const sideArtImage = getImage(sideart)
  return (
    <Layout title={title} description={description}>
      <InnerPageNavigation />
      {sidebar ? (
        <aside className="absolute right-0 hidden mt-48 mr-24 xl:mr-64 sidebar lg:block">
          <h2 className="mb-1 text-xl border-b ">{sidebar.title}</h2>
          <ul className="mt-4 list-none">
            {sidebar.items.map((item: SidebarItem) => (
              <li key={item.title} className="ml-2">
                <h3 className="font-sans text-base italic">{item.title}</h3>
                <span className="block">{item.date}</span>
                <span className="block">{item.time}</span>
              </li>
            ))}
          </ul>
        </aside>
      ) : null}
      <div
        className={`container flex-grow px-4 mx-auto mt-10 mb-10 innerpage relative ${namespace}`}
      >
        {sideArtImage ? (
          <div className="absolute top-0 right-0 hidden mr-0 lg:mr-2 xl:mr-16 lg:block sideart">
            <Img image={sideArtImage} alt="" />
          </div>
        ) : null}
        <div className="mx-12 xl:mx-24 2xl:mx-32 md:max-w-xl xl:max-w-2xl">
          <h1 className="mb-3 font-serif text:2xl sm:text-3xl md:text-4xl text-dark-burgundy">
            {title}
          </h1>
          <MDXRenderer className={namespace}>{body}</MDXRenderer>
        </div>
      </div>
      <PageTileGroup />
    </Layout>
  )
}

interface InnerPageWithSidebarProps {
  data: {
    mdx: {
      frontmatter: {
        title: string
        description: string
        namespace: string
        sidebar: {
          title: string
          items: SidebarItem[]
        }
        sideart: ImageDataLike
      }
      body: string
    }
  }
}

const InnerPage: React.FC<InnerPageWithSidebarProps> = ({ data }) => {
  const { frontmatter, body } = data.mdx
  return (
    <InnerpageWithSidebarTemplate
      title={frontmatter.title}
      description={frontmatter.description}
      body={body}
      namespace={frontmatter.namespace}
      sidebar={frontmatter.sidebar}
      sideart={frontmatter.sideart}
    />
  )
}

export default InnerPage

export const InnerPageQuery = graphql`
  query InnerPageWithSidebarByID($id: String!) {
    mdx(id: { eq: $id }) {
      id
      body
      frontmatter {
        title
        description
        namespace
        sidebar {
          title
          items {
            title
            date
            time
          }
        }
        sideart {
          childImageSharp {
            gatsbyImageData(
              width: 400
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    }
  }
`
